
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FileUpload from "@/components/scope/file-upload.vue";
import Editor from "@common/src/components/scope/editor/index.vue";
import { priceRulesEl, isPositiveInteger, priceRules } from "@/utils/verify";
import { emptyToLine } from "@/utils/common";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import t from "@common/src/i18n/t";
const app = namespace("app");
const serviceItem = namespace("service-item");
const parts = namespace("parts");
const coupon = namespace("coupon");
const campaign = namespace("campaign");
const store = namespace("store");

const defaultBannerUrl =
  "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/speedbiz/minipro/turnplate-banner.png";
const unluckyUrl = "https://speedbiz-public.oss-cn-beijing.aliyuncs.com/static/speedbiz/campaign/unlucky.png";
@Component({
  components: { FileUpload, Editor }
})
export default class CollectCustomerEdit extends Vue {
  @serviceItem.Action getServiceList;
  @campaign.Action createSaveLottery;
  @parts.Action getPartsInventoryList;
  @campaign.Action queryLotteryDetail;
  @coupon.Action("queryList") queryCouponList;
  @app.Mutation setPBPX;
  @app.Mutation btnStartLoading;
  @app.Mutation btnStopLoading;
  @store.State storeData;
  dayjs = dayjs;
  t = t;
  emptyToLine = emptyToLine;
  campaignForm: any = {
    campaignType: "15", // 15:大转盘 20砸金蛋
    campaignImage: "",
    campaignName: "",
    bannerType: "default", // default customize
    campaignBeginTime: "",
    campaignEndTime: "",
    campaignDesc: "",
    campaignContext: "",
    prizeArray: [],
    threshold: {
      campaignCode: "", //(5.无门槛, 10.满金额, 15.指定服务, 20.指定零配件, 25 指定零配件满金额)
      thresholdAmount: "",
      thresholdCode: "",
      thresholdMethod: "5",
      thresholdPartsCodeArray: [],
      thresholdServiceCodeArray: []
    },
    // 前端用
    time: []
  };
  campaignFormRules: any = {
    campaignName: { required: true, message: t("v210801.please-enter-the-campaign-title"), trigger: "blur" },
    time: { type: "array", required: true, message: t("v210801.please-select-an"), trigger: "change" },
    campaignImage: { required: true, validator: this.checkFrontImage }
  };
  thresholdFormRules: any = {
    thresholdAmount: [
      { required: true, message: t("v210831.please-enter-amount"), trigger: "blur" },
      { validator: this.checkPrice, trigger: "blur" }
    ],
    thresholdPartsCodeArray: {
      type: "array",
      required: true,
      message: t("setting.select-spare-parts"),
      trigger: "change"
    },
    thresholdServiceCodeArray: {
      type: "array",
      required: true,
      message: t("service.select-service-item"),
      trigger: "change"
    }
  };
  showCouponSelector: boolean = false;
  get breadData() {
    return [
      { name: t("v210801.lucky-draw"), path: "/marketing/campaign/lottery" },
      {
        name: t("v210831.view-lucky-draw")
      }
    ];
  }
  get id() {
    return this.$route.params.id;
  }
  campaignStatusList = [];
  get isAdd() {
    return this.$route.name === "lotteryAdd";
  }
  checkPrice(rule, value, callback) {
    priceRulesEl(value, callback);
  }
  definitionAward: any = {
    bindCode: "",
    bindObj: {},
    campaignCode: "",
    prizeCode: "",
    prizeImage: "",
    prizeName: "",
    prizeQty: "",
    prizeStatus: "",
    prizeStatusDesc: "",
    prizeType: "",
    winningRate: "",
    rowId: ""
  };
  created() {
    // this.setPBPX(52);
    this.init();
    this.searchServiceItem("");
    this.searchParts("");
    this.searchCoupon("");
  }
  mounted() {}
  checkFrontImage(rule, value, callback) {
    if (this.campaignForm.bannerType === "default") {
      callback();
    } else {
      if (!value) {
        callback(new Error(t("v210831.please-upload-the-cover-img")));
      } else {
        callback();
      }
    }
  }
  handleBannerChange(val) {
    if (val === "default") {
      this.campaignForm.campaignImage = defaultBannerUrl;
      this.defaultBannerList = [];
    } else {
      this.$nextTick(() => {
        this.campaignForm.campaignImage = "";
      });
    }
  }
  get copyCode() {
    return this.$route.query.copyCode;
  }
  rowId: number = 0;
  defaultBannerList: any = [];
  init() {
    if (!this.isAdd || this.copyCode) {
      this.queryLotteryDetail({
        campaignCode: this.id || this.copyCode
      }).then(data => {
        data.data.time = [
          dayjs(data.data.campaignBeginTime).format("YYYYMMDDHHmmss"),
          dayjs(data.data.campaignEndTime).format("YYYYMMDDHHmmss")
        ];
        if (this.copyCode) {
          delete data.data.campaignCode;
        }
        data.data.prizeArray.forEach(prize => {
          prize.rowId = ++this.rowId;
          if (
            !this.couponList.some(coupon => coupon.activityCode === prize.bindCode) &&
            !prize.saveFlag &&
            prize.bindCode
          ) {
            this.couponList.push({
              activityCode: prize.bindObj.activityCode,
              activityName: prize.bindObj.activityName
            });
          }
          if (prize.saveFlag) {
            prize.prizeImage = unluckyUrl;
          }
        });
        this.campaignForm.bannerType = "customize";
        this.defaultBannerList = [{ url: data.data.campaignImage }];
        Object.assign(this.campaignForm, data.data);
      });
    } else {
      this.definitionAward.rowId = String(++this.rowId);
      this.campaignForm.prizeArray.push(
        Object.assign({}, this.definitionAward, {
          prizeName: t("v210831.thank-you-for"),
          type: "default",
          saveFlag: "0", // 标注谢谢参与
          prizeImage: unluckyUrl
        })
      );
    }
  }
  handleComputedRate() {
    try {
      const winRateSum = this.campaignForm.prizeArray.reduce((prev, curv) => {
        console.log(prev, curv.winningRate);
        return prev + (curv.saveFlag === "0" ? 0 : Number(curv.winningRate));
      }, 0);
      const unWinRate = (100 - winRateSum).toFixed(2);
      this.campaignForm.prizeArray[this.campaignForm.prizeArray.length - 1].winningRate = unWinRate;
    } catch (e) {
      this.campaignForm.prizeArray[this.campaignForm.prizeArray.length - 1].winningRate = "-";
    }
  }
  handleAddAward() {
    this.definitionAward.rowId = String(++this.rowId);
    // console.log(Object.assign({}, this.definitionAward));
    this.campaignForm.prizeArray.unshift({});
  }
  searchServiceItem(val) {
    this.getServiceItemList(val);
  }
  serviceItemList: any = [];
  partsList: any = [];
  couponList: any = [];
  getServiceItemList(name = "") {
    this.getServiceList({
      pageSize: 20,
      pageNum: 1,
      searchKey: name,
      serviceClass: ""
    }).then(data => {
      this.serviceItemList = this.$safeValue(data, "data.list", []);
    });
  }
  searchCoupon(val) {
    this.getCouponList(val);
  }
  getCouponList(name = "") {
    this.queryCouponList({
      pageSize: 20,
      pageNum: 1,
      activityName: name
    }).then(data => {
      console.log(data.data.list);
      this.couponList = this.$safeValue(data, "data.list", []);
    });
  }
  searchParts(val) {
    this.getPartsLists(val);
  }
  getPartsLists(name = "") {
    this.getPartsInventoryList({
      pageNum: 1,
      pageSize: 20,
      searchKey: name
    }).then(data => {
      this.partsList = this.$safeValue(data, "data.list", []);
    });
  }
  handleRemoveAward(idx, row) {
    if (row.saveFlag) {
      return false;
    }
    this.campaignForm.prizeArray.splice(idx, 1);
  }
  handleBannerUploadSuccess(url) {}
  handleSelectedCondType(type) {
    return;
    // if (type === this.campaignForm.threshold.thresholdMethod) {
    //   return false;
    // }
    // this.campaignForm.threshold.thresholdMethod = type;
    // this.campaignForm.threshold.thresholdAmount = "";
    // this.campaignForm.threshold.thresholdPartsCodeArray = [];
    // this.campaignForm.threshold.thresholdServiceCodeArray = [];
  }
  handleSearch() {}
  saveCampaign() {
    (this.$refs.campaignForm as any).validate(valid1 => {
      console.log(this.$refs.thresholdForm);
      (this.$refs.thresholdForm as any).validate(valid2 => {
        if (valid1 && valid2 && this.checkLotteryData()) {
          this.campaignForm.campaignBeginTime = this.campaignForm.time[0];
          this.campaignForm.campaignEndTime = this.campaignForm.time[1];
          this.btnStartLoading();
          this.createSaveLottery(this.campaignForm)
            .then(data => {
              this.isAdd
                ? this.$message.success(t("v210831.lucky-draw-successfully"))
                : this.$message.success(t("v210831.lucky-draw-edited"));
              this.$router.push("/marketing/campaign/lottery");
            })
            .finally(() => {
              this.btnStopLoading();
            });
        }
      });
    });
  }
  checkLotteryData() {
    const prizeList = this.campaignForm.prizeArray;
    let rateSum = "";
    for (let i = 0; i < prizeList.length; i++) {
      if (!prizeList[i].prizeName) {
        this.$message.error(t("v210831.please-enter-the-prize-name"));
        return false;
      }
      if (prizeList[i].saveFlag !== "0" && !isPositiveInteger(prizeList[i].prizeQty)) {
        this.$message.error(t("v210831.the-number-of-positive-integer"));
        return false;
      }
      if (prizeList[i].saveFlag !== "0" && !priceRules(prizeList[i].winningRate).success) {
        this.$message.error(t("v210831.the-prize-probability-two"));
        return false;
      }
      if (prizeList[i].saveFlag !== "0") {
        rateSum += Number(prizeList[i].winningRate).toFixed(2);
      }
      if (i === prizeList.length - 1 && Number(rateSum) > 100) {
        this.$message.error(t("v210831.the-total-winning-100"));
        return false;
      }
    }
    return true;
  }
  showLinkQr: boolean = false;
  handleComfirmCommand(command) {
    if (command === "push") {
      this.$router.push(`/marketing/campaign/send-lottery-qualification/${this.id}`);
    } else if (command === "copyLink") {
      this.showLinkQr = true;
      setTimeout(() => {
        this.qrCode(this.campaignFullUrl);
      }, 200);
    }
  }
  get campaignFullUrl() {
    if (this.campaignForm.campaignType === "15") {
      return `${process.env.VUE_APP_H5_SITE}/wx-jump?storeCode=${
        this.storeData.storeCode
      }&urlType=h5&url=${encodeURIComponent(
        process.env.VUE_APP_H5_SITE + "/campaign/turn-draw?campaignCode=" + this.id
      )} `;
    } else {
      return `${process.env.VUE_APP_H5_SITE}/wx-jump?storeCode=${
        this.storeData.storeCode
      }&urlType=h5&url=${encodeURIComponent(
        process.env.VUE_APP_H5_SITE + "/campaign/gold-egg?campaignCode=" + this.id
      )} `;
    }
  }
  qrCode(url) {
    document.getElementById("qrcode").innerHTML = "";
    let qrcode = new QRCode("qrcode", {
      width: 120, //图像宽度
      height: 120, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(url); //生成另一个新的二维码
  }
  handleDownloadQR() {
    var imgs = document.getElementById("qrcode").getElementsByTagName("img");
    var a = document.createElement("a");
    a.download = "QR" + new Date().getTime() || t("v210801.download-picture-name");
    a.href = imgs[0].src;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  handleEdit() {
    this.$router.push(`/marketing/campaign/lottery-edit/${this.id}`);
  }
}
